import React, { useEffect, useState } from "react";
import {
  Grid,
  Box,
  OutlinedInput,
  Button,
  Link,
  RadioGroup,
  FormControlLabel,
  Radio,
  InputLabel,
  FormControl,
} from "@material-ui/core";
import styles from "./Enrollment.module.scss";
import { useGlobalUserData } from "../lib/globalUserData";
import LoaderFis from "../components/Loader/LoaderFIS";
import { axiosInstance } from "../services/customaxios";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { useTranslation } from "../contexts/Localization";
import FisModal from "./Modal";
import { getMessage } from "../utils/status";
import FisAlert from "./FisAlert";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { formatDevices } from "../utils/formatDevices";
import { isValidEmail } from "../utils/isValidEmail";
import { emailMask } from "../utils/mask";
import FisTranslatedAlert from "./FisTranslatedAlert";

const FISTroubleSIgnIn = () => {
  const { changeStep, userData, updateUserData, showGlobalAlert, globalAlert } =
    useGlobalUserData();
  const { t, apiLanguage } = useTranslation();
  const [formData, setFormData] = useState({
    troublesigninforgotuser: "",
    troublesigninforgotpass: "",
    troublesigninaccountlocked: "",
    accountType: "empty",
  });
  const [loading, setLoading] = useState(false);
  const [localAlert, setLocalAlert] = useState({ type: "", message: "" });
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [formErrors, setFormErrors] = useState({
    troublesigninforgotuser: "",
    troublesigninforgotpass: "",
    troublesigninaccountlocked: "",
  });
  const [alertMessage, setAlertMessage] = useState({ title: "", body: "" });
  const [batchEmail, setBatchEmail] = useState("");
  const [rememberOptions, setRememberOptions] = useState([
    {
      name: "troublesigninforgotuser",
      id: "forgotusername",
      value: "I forgot my User ID",
      isChecked: true,
    },
    {
      name: "troublesigninforgotpass",
      id: "forgotpassword",
      value: "I forgot my Password",
      isChecked: false,
    },
    {
      name: "troublesigninaccountlocked",
      id: "accountlocked",
      value: "My account is locked",
      isChecked: false,
    },
  ]);

  useEffect(() => {
    updateUserData({
      ...userData,
      troubleSigninStep: "troublesigninforgotuser",
      flow: "TROUBLE_SIGN_IN",
    });
    showGlobalAlert({ type: "", message: "" });
    setLocalAlert({ type: "", message: "" });
    // return () => {
    //   setLocalAlert({ type: '', message: '' });
    //   showGlobalAlert({ type: '', message: '' });
    // };
  }, []);

  const alertErrorOpen = () => {
    setShowErrorAlert(true);
  };
  const alertErrorClose = () => {
    setShowErrorAlert(false);
  };

  const usernameFullFlow = () => {
    setLoading(true);
    axiosInstance
      .post(
        `${process.env.REACT_APP_IDM_API}authentication/forgotusername`,
        {
          step: "username",
          context: "trouble sign in recover user id",
          data: {
            next: "true",
            emailOrLoginID: formData[userData.troubleSigninStep],
            email: "true",
            issueType: userData.issueTypes[userData.troubleSigninStep],
          },
        },
        {
          // withCredentials: 'include',
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-api-key": process.env.REACT_APP_X_API_KEY_AUTHENTICATION,
            appid: "idm",
            language: apiLanguage,
          },
        }
      )
      .then((res) => {
        setLoading(false);
        if (
          res.data.status.code === 501 ||
          res.data.status.code === 503 ||
          res.data.status.code === 505
        ) {
          alertErrorOpen();
          setAlertMessage({
            title: t("Invalid Email"),
            body: t(
              "We could not locate an account with the information provided. Please try again or contact customer support."
            ),
          });
        } else if (res.data.status.code === 102) {
          showGlobalAlert({
            type: "error",
            message: getMessage(res.data.status.code).body,
          });
        } else if (res.data.status.code !== 200) {
          const { body } = getMessage(res.data.status.code);
          showGlobalAlert({ type: "error", message: body });
        } else if (res.data.status.code === 200) {
          const devicesList = res.data.result[0].nextStep.params?.devices;
          if (devicesList && devicesList.length > 0) {
            updateUserData({
              ...userData,
              otpDestinations: devicesList,
              userid: formData[userData.troubleSigninStep],
              otpDeviceList: formatDevices(devicesList),
            });
            setLocalAlert({ type: "", message: "" });
            showGlobalAlert({ type: "", message: "" });
            changeStep("fistroubleotpchoice");
          } else {
            updateUserData({
              ...userData,
              userid: formData[userData.troubleSigninStep],
              userpassword: formData.userpassword,
              password: formData.userpassword,
            });
            setLoading(false);
            alertErrorOpen();
            console.error(err);
            setAlertMessage({
              title: t("Error occurred"),
              body: t(
                "We are unable to process your request. Please try again later."
              ),
            });
          }
        }
      })
      .catch((err) => {
        updateUserData({
          ...userData,
          userid: formData[userData.troubleSigninStep],
          password: formData.userpassword,
          userpassword: formData.userpassword,
        });
        setLoading(false);
        console.error(err);
        alertErrorOpen();
        setAlertMessage({
          title: t("Error occurred"),
          body: t(
            "We are unable to process your request. Please try again later."
          ),
        });
      });
  };

  // migration check code. can be done pre trouble sign in flow.
  // can be uncommented and used when required :
  const checkMigStatus = async () => {
    setLoading(true);
    const info = formData[userData.troubleSigninStep];
    const validMail = isValidEmail(info);
    try {
      const { data } = await axiosInstance.post(
        `${process.env.REACT_APP_IDM_API}authentication/migration-status-check`,
        {
          [validMail ? "email" : "userId"]: info,
        },
        {
          headers: {
            appid: "idm",
            language: apiLanguage,
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-api-key": process.env.REACT_APP_X_API_KEY_AUTHENTICATION,
          },
        }
      );
      return data;
    } catch (error) {
      setLoading(false);
      console.log(error);
      showGlobalAlert({
        type: "error",
        message: getMessage("defaultCode").body,
      });
    }
  };
  const setErrors = () => {
    const tstep = userData.troubleSigninStep;
    const data = formData[userData.troubleSigninStep];
    switch (tstep) {
      case "troublesigninforgotuser":
        if (!isValidEmail(data) || data === "") {
          setFormErrors({
            ...formErrors,
            [tstep]: "Please enter valid Email Address",
          });
        }
        break;
      case "troublesigninforgotpass":
      case "troublesigninaccountlocked":
      default:
        if (data === "") {
          setFormErrors({
            ...formErrors,
            [tstep]: "User ID / Email is required",
          });
        }
        break;
    }
  };

  const performBatchAuth = ({ userId, email }) => {
    setLoading(true);
    axiosInstance
      .post(
        `${process.env.REACT_APP_IDM_API}authentication/batch/batchAuth`,
        {
          userId,
        },
        {
          withCredentials: "include",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-api-key": process.env.REACT_APP_X_API_KEY_AUTHENTICATION,
            appid: "idm",
            language: apiLanguage,
          },
        }
      )
      .then((res) => {
        setLoading(false);
        if (res.data.status.code === 111) {
          showGlobalAlert({
            type: "error",
            message: getMessage(111).body,
          });
        } else if (res.data.status.code !== 200) {
          showGlobalAlert({
            type: "error",
            message: getMessage("defaultCode").body,
          });
        } else {
          // setBatchEmail(email);
          // setLocalAlert({
          //   type: 'success',
          //   message:
          //     'A link to reset your password has been sent to the registered email address %emailId%.',
          // });
          showGlobalAlert({
            type: "success",
            message: t(
              "A link to reset your password has been sent to the registered email address %emailId%.",
              { emailId: emailMask(email) }
            ),
          });
          changeStep("fislogin");
        }
      })
      .catch((err) => {
        setLoading(false);
        showGlobalAlert({
          type: "error",
          message: getMessage("defaultCode").body,
        });
        console.error(err);
        updateUserData({
          ...userData,
          password: "",
          userpassword: "",
        });
      });
  };
  // testcode delete please const alertcheck = () => {
  //   const email = 'jognjoe@thuoru.io';
  //   showGlobalAlert({
  //     type: 'error',
  //     message: t(
  //       'A link to reset your password has been sent to the registered email address %emailId%.',
  //       { emailId: emailMask(email) }
  //     ),
  //   });
  // }
  const submitUsername = async () => {
    if (
      !formData[userData.troubleSigninStep] ||
      formErrors[userData.troubleSigninStep] ||
      !rememberOptions.some((opt) => opt.isChecked)
    ) {
      setErrors();
    } else if (sessionStorage.getItem("appId") === "dummy") {
      usernameFullFlow();
    } else {
      setLocalAlert({ type: "", message: "" });
      const migData = await checkMigStatus();
      setLoading(false);
      switch (migData?.status?.toLowerCase()) {
        case "inprogress":
        case "in progress":
          if (migData?.migrationOver) {
            usernameFullFlow();
            break;
          } else {
            showGlobalAlert({
              type: "error",
              message:
                "We are updating our systems. For help, contact us at 1.800.981.5554 (toll-free), 787.620.0000 or through the chat at orientalbank.com.",
            });
            break;
          }
        case "completed":
        case "enrolled":
          usernameFullFlow();
          break;
        case "passreset":
          if (userData.issueTypes[userData.troubleSigninStep] === "PASSWORD") {
            performBatchAuth(migData);
            break;
          } else {
            usernameFullFlow();
            break;
          }
        case "hold":
        case "ready":
        case "failed":
          if (migData?.migrationOver) {
            usernameFullFlow();
            break;
          } else {
            showGlobalAlert({
              type: "error",
              message:
                "To change your username or password, contact us at 1.800.981.5554 (toll-free), 787.620.0000 or through the chat at orientalbank.com.",
            });
            break;
          }
        case "pending":
          if (migData?.migrationOver) {
            usernameFullFlow();
            break;
          } else {
            showGlobalAlert({
              type: "error",
              message:
                "Do you need help signing in? Contact us at 787.620.0000 or 1.800.981.5554 or via chat on orientalbank.com.",
            });
            break;
          }
        default:
          setLoading(false);
          showGlobalAlert({
            type: "error",
            message: getMessage("defaultCode").body,
          });
          break;
      }

      // if (status === 'completed') {
      //   usernameFullFlow();
      // } else if (incompleteStatuses.includes(status)) {
      //   // setSubmitDisabled(false); // this 'else' block could be used to redirect the user in case of 'non complete' mig status
      //   // usernameFullFlow();
      //   showGlobalAlert({ type: 'error', message: 'We are unable to process your request. Please contact customer care.' });
      //   changeStep('fislogin');
      // }
      // else if (status === 'inprogress' || status === 'in progress') {
      //   showGlobalAlert({ type: 'error', message: 'You are being logged into another session. Please wait.' })
      //   changeStep('fislogin');
      // }
      // else {
      //   showGlobalAlert({
      //     type: 'error',
      //     message: getMessage('defaultCode').body,
      //   });
      // }
      // usernameFullFlow();
      // also not at all code setSubmitDisabled(false);
    }
  };

  // nononot code const onFormDataOld = (e) => {
  //   if (e.target.value) {
  //     setSubmitDisabled(false);
  //   }
  //   if (userData.troubleSigninStep === 'troublesigninforgotuser') {
  //     if (/[a-z0-9]+@[a-z]+\.[a-z]{2,3}/.test(e.target.value)) {
  //       setFormData({
  //         ...formData,
  //         [e.target.name]: e.target.value,
  //       });
  //       setFormErrors({
  //         ...formErrors,
  //         [e.target.name]: '',
  //       });
  //     }
  //     else {
  //       setFormData({
  //         ...formData,
  //         [e.target.name]: e.target.value,
  //       });
  //       setSubmitDisabled(true);
  //     }
  //   } else {
  //     setFormData({
  //       ...formData,
  //       [e.target.name]: e.target.value,
  //     });
  //     setFormErrors({
  //       ...formErrors,
  //       [e.target.name]: '',
  //     });
  //   }
  // };
  const onFormData = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
    switch (e.target.name) {
      case "troublesigninforgotuser":
        if (!isValidEmail(e.target.value) || e.target.value === "") {
          setFormErrors({
            ...formErrors,
            [e.target.name]: "Please enter valid Email Address",
          });
        } else {
          setFormErrors({
            ...formErrors,
            [e.target.name]: "",
          });
        }
        break;
      case "troublesigninforgotpass":
      case "troublesigninaccountlocked":
      default:
        if (e.target.value === "") {
          setFormErrors({
            ...formErrors,
            [e.target.name]: "User ID / Email is required",
          });
        } else {
          setFormErrors({
            ...formErrors,
            [e.target.name]: "",
          });
        }
        break;
    }
    //nononono not code  setFormData({
    //   ...formData,
    //   [e.taret.name]: e.target.value,
    // });
    // setFormErrors({
    //   ...formErrors,
    //   [e.target.name]: errMsg(e.target.value)
    // })
  };
  const onCheckBoxChange = (event) => {
    setLocalAlert({ type: "", message: "" });
    showGlobalAlert({ type: "", message: "" });
    let tempOptions = [...rememberOptions];
    tempOptions.forEach((options) => {
      options.isChecked = options.name === event.target.name;
    });
    setRememberOptions(tempOptions);
    updateUserData({ ...userData, troubleSigninStep: event.target.name });
  };

  const navigateBack = () => {
    showGlobalAlert({ type: "", message: "" });
    setLocalAlert({ type: "", message: "" });
    updateUserData({
      ...userData,
      troubleSigninStep: "troublesigninforgotuser",
    });
    changeStep("fislogin");
  };

  return (
    <Grid container justifyContent="center">
      <Grid item md={4} style={{ maxWidth: "100%" }}>
        <Box className={styles.container}>
          <div style={{ width: "90%" }}>
            <div className={styles.backArrow}>
              <div data-testid="backButtonTSI">
                <ArrowBackIcon
                  data-testid="backBtnTSI"
                  name="backbtn-TSI"
                  onClick={navigateBack}
                />
              </div>
              <h4
                className={styles.middle_heading}
                id="TSIheading"
                name="TSI-heading"
              >
                {t("Trouble Signing In?")}
              </h4>
            </div>

            <form
              noValidate
              id="troublesignin"
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              <span
                id="userId-err-msg"
                style={{
                  font: "normal normal normal 14px/16px Arial",
                  letterSpacing: "0.1px",
                  color: "#DF0000",
                  opacity: 1,
                }}
                role="alert"
              >
                {formErrors.userid}
              </span>
              {localAlert.message && (
                <FisTranslatedAlert
                  message={t(
                    "A link to reset your password has been sent to the registered email address %emailId%.",
                    { emailId: emailMask(batchEmail) }
                  )}
                  type={globalAlert.type}
                />
              )}
              {globalAlert.message && (
                <FisAlert
                  message={globalAlert.message}
                  type={globalAlert.type}
                />
              )}
              <RadioGroup>
                {rememberOptions.map((item) => (
                  <FormControlLabel
                    value={`${item.id}_${item.name}`}
                    name={item.name}
                    key={item.id}
                    data-testid={item.id}
                    id={item.value}
                    control={
                      <Radio
                        size="small"
                        style={{ color: "#D0D0D0" }}
                        classes={{ checked: styles.radioColor }}
                        checked={item.isChecked}
                      />
                    }
                    label={t(item.value)}
                    onChange={onCheckBoxChange}
                    classes={{
                      label: styles.radioLabel,
                    }}
                  />
                ))}
              </RadioGroup>
              <Box
                style={{
                  margin: "1rem 0",
                  maxWidth: "394px",
                  fontSize: "16px",
                  fontWeight: "bold",
                }}
                id="TSI-forgorUser"
                name="TSI-forgorUser"
              >
                {userData.troubleSigninStep &&
                userData.troubleSigninStep === "troublesigninforgotuser"
                  ? t("Please provide your registered email address to proceed")
                  : t(
                      "Please provide your user ID or registered email address to proceed"
                    )}
              </Box>
              <FormControl
                style={{ width: "100%" }}
                error={formErrors[userData.troubleSigninStep]}
                variant="outlined"
              >
                <InputLabel
                  className={styles.label}
                  // name={userData.troubleSigninStep}
                  id="userID"
                  htmlFor="userid"
                  aria-label="userid"
                >
                  {t(
                    userData.troubleSigninStep &&
                      userData.troubleSigninStep === "troublesigninforgotuser"
                      ? "Email"
                      : "User ID / Email"
                  )}
                </InputLabel>
                <OutlinedInput
                  id="userid"
                  name={userData.troubleSigninStep}
                  onChange={onFormData}
                  onBlur={onFormData}
                  placeholder={t(
                    userData.troubleSigninStep &&
                      userData.troubleSigninStep === "troublesigninforgotuser"
                      ? "Email"
                      : "Enter User ID / Email Address"
                  )}
                  fullWidth
                  inputProps={{ maxLength: 50 }}
                  autoComplete="off"
                  value={formData[userData.troubleSigninStep]}
                  className={styles.passwordInput}
                />
              </FormControl>
              {formErrors[userData.troubleSigninStep] && (
                <Box className={styles.inlineAlert}>
                  <InfoOutlinedIcon fontSize="small" />
                  {t(formErrors[userData.troubleSigninStep])}
                </Box>
              )}
              <Box className={styles.btn_wrapper}>
                <Button
                  type="submit"
                  id="continueBtn"
                  name="continue-btn"
                  variant="text"
                  // color="secondary"
                  onClick={submitUsername}
                  style={{ width: "386px" }}
                  className={styles.login_button}
                >
                  {t("Continue")}
                </Button>
              </Box>
            </form>
            <Box className={styles.link_wrapper} id="linkBoxWrapper">
              <Link
                component="button"
                variant="body2"
                id="loginLink"
                color="primary"
                onClick={() => {
                  showGlobalAlert({ type: "", message: "" });
                  setLocalAlert({ type: "", message: "" });
                  changeStep("fislogin");
                }}
                style={{ textDecoration: "underline" }}
              >
                {t("Log in with a different User ID")}
              </Link>
              <Box id="enrollWrapperBox">
                {t("Don't have an online banking?")}{" "}
                <Link
                  component="button"
                  variant="body2"
                  color="primary"
                  id="loginLinkEnrollment"
                  onClick={() => {
                    showGlobalAlert({ type: "", message: "" });
                    setLocalAlert({ type: "", message: "" });
                    changeStep("enrollment");
                  }}
                >
                  {t("Enroll")}
                </Link>
              </Box>
            </Box>
          </div>
        </Box>
      </Grid>
      <LoaderFis open={loading} />
      <FisModal
        id={alertMessage.title}
        open={showErrorAlert}
        onClose={alertErrorClose}
        alertTitle={alertMessage.title}
        alertBody={alertMessage.body}
        onConfirm={() => {
          alertErrorClose();
        }}
      />
    </Grid>
  );
};

export default FISTroubleSIgnIn;
